<template>
  <v-carousel
    ref="container"
    v-model="currentIndex"
    :height="height"
    :touchless="zoomed"
    :show-arrows="isMulti"
    :hide-delimiters="!isMulti"
  >
    <v-carousel-item v-for="image in images" :key="image.url || image">
      <v-sheet color="grey" height="100%" tile>
        <v-zoomer style="width: 100%; height: 100%;" :zoomed.sync="zoomed">
          <v-img :src="`${image.url || image}?t=cover&w=${width}&h=${height}`" contain height="100%">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-zoomer>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import Vue from 'vue'
import VueZoomer from 'vue-zoomer'

Vue.use(VueZoomer)

export default {
  props: {
    images: { type: Array, default: () => [] },
    selected: { type: Object, default: () => {} },
  },
  data: () => ({
    currentIndex: 0,
    zoomed: false,
    width: 900,
    height: 600,
  }),
  computed: {
    isMulti() {
      return this.images.length > 1
    },
  },
  created() {
    let index = this.images.indexOf(this.selected)
    if (index >= 0) this.currentIndex = index
  },
  mounted() {
    this.width = this.$refs.container.$el.clientWidth
    this.height = parseInt(0.66 * this.width)
  },
}
</script>
